// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep adis-search-results {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 225px);
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app-shell/src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,2BAAA;EACA,gBAAA;AACF","sourcesContent":["::ng-deep adis-search-results {\n  display: flex;\n  flex-direction: column;\n  height: calc(100vh - 225px);\n  text-align: left;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
